// ContactPage.js
import React, { useState } from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Input,
  Textarea,
  Button,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import SEO from "../components/SEO";

const ContactPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleMailTo = () => {
    const subject = `Contact Form Submission from ${name}`;
    const body = `Name: ${name}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`;
    window.location.href = `mailto:support@yourdomain.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  const bg = useColorModeValue("gray.50", "gray.700");
  const textColor = useColorModeValue("gray.800", "white");
  const formBg = useColorModeValue("gray.100", "gray.600");

  return (
    <>
      <SEO
        title="Contact Us - High Value Man"
        description="Get in touch with the High Value Man team. We’re here to help with any questions about our content, the High Value Man Score, or personal development resources."
        keywords="contact High Value Man, get in touch, High Value Man support, personal development questions"
        url="https://www.highvalueman.life/contact"
      />

      <Box bg={bg} color={textColor} py={10} px={6}>
        {/* Hero Section */}
        <VStack spacing={8} textAlign="center">
          <Heading as="h1" size="2xl">
            Contact Us
          </Heading>
          <Text fontSize="lg" maxW="800px">
            Have a question, comment, or want to get in touch? We'd love to hear
            from you. Fill out the form below, and we'll get back to you as soon
            as possible.
          </Text>
        </VStack>

        {/* Contact Form Section */}
        <Box bg={formBg} p={8} mt={10} borderRadius="md" maxW="600px" mx="auto">
          <VStack spacing={4} as="form">
            <FormControl id="name" isRequired>
              <FormLabel>Your Name</FormLabel>
              <Input
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel>Your Email</FormLabel>
              <Input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl id="message" isRequired>
              <FormLabel>Your Message</FormLabel>
              <Textarea
                placeholder="Enter your message"
                rows={5}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </FormControl>
            <Button
              colorScheme="teal"
              size="lg"
              mt={4}
              type="button"
              onClick={handleMailTo}
            >
              Send Message
            </Button>
          </VStack>
        </Box>
      </Box>
    </>
  );
};

export default ContactPage;
