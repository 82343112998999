import React from "react";
import {
  Box,
  Heading,
  Image,
  Text,
  useColorMode,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { blogConfig, blogImageMap } from "../constants/blogConfig";
import parse, { domToReact } from "html-react-parser";
import SEO from "../components/SEO";

const BlogPost = () => {
  const { colorMode } = useColorMode();

  const { id } = useParams(); // Get the blog post ID from the URL
  const blog = blogConfig.find((post) => post.id === id);

  const bg = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");

  if (!blog) {
    return (
      <Box p={8} bgColor={bg} borderRadius="md">
        <Heading as="h2" size="lg" color={textColor}>
          Blog Post Not Found
        </Heading>
      </Box>
    );
  }

  const options = {
    replace: ({ name, attribs, children }) => {
      if (name === "a" && attribs.href) {
        return (
          <RouterLink to={attribs.href} {...attribs}>
            {domToReact(children)}
          </RouterLink>
        );
      }
    },
  };

  return (
    <>
      <SEO
        title={blog.seoTitle || blog.title}
        description={blog.seoDescription}
        keywords={blog.metaKeywords.join(", ")}
        url={`https://www.highvalueman.life/blogs/${id}`}
        image={`https://www.highvalueman.life/${id}.jpg`}
      />
      <Box p={8} borderRadius="md" bgColor={bg}>
        <VStack
          spacing={16}
          align="start"
          mt="8"
          maxW={["100%", "90%", "80%", "900px"]}
          mx="auto"
        >
          <Heading as="h1" size="2xl" color={textColor}>
            {blog.title}
          </Heading>
          <Image src={blogImageMap[id]} alt={blog.title} borderRadius="md" />
          <Box
            as="article"
            color={textColor}
            className={colorMode === "light" ? "light-mode" : "dark-mode"}
          >
            {blog.content && parse(blog.content, options)}
          </Box>
        </VStack>
      </Box>
    </>
  );
};

export default BlogPost;
