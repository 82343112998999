// PrivacyPage.js
import React from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import SEO from "../components/SEO";

const PrivacyPage = () => {
  const bg = useColorModeValue("gray.50", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");

  return (
    <>
      <SEO
        title="Privacy Policy - High Value Man"
        description="Review the privacy policy of High Value Man. Learn how we handle your data, cookies, and personal information to ensure your privacy and security."
        keywords="High Value Man privacy policy, data handling, personal information security, cookies"
        url="https://www.highvalueman.life/privacy-policy"
      />
      <Box bg={bg} color={textColor} py={10} px={6}>
        {/* Hero Section */}
        <VStack spacing={8} textAlign="center">
          <Heading as="h1" size="2xl">
            Privacy Policy
          </Heading>
          <Text fontSize="lg" maxW="800px">
            Your privacy is important to us. This privacy policy explains what
            personal data we collect, how we use it, and your rights regarding
            your data.
          </Text>
        </VStack>

        {/* Privacy Content */}
        <VStack spacing={8} mt={10} align="start" maxW="800px" mx="auto">
          <Heading as="h2" size="xl">
            Information We Collect
          </Heading>
          <Text fontSize="lg">
            We collect personal information that you provide to us, such as your
            name, email address, and any other details you share when you
            contact us, subscribe to our newsletter, or use our services.
          </Text>

          <Heading as="h2" size="xl">
            How We Use Your Information
          </Heading>
          <Text fontSize="lg">
            Your information is used to provide and improve our services,
            respond to inquiries, and communicate with you. We do not share your
            information with third parties without your consent.
          </Text>

          <Heading as="h2" size="xl">
            Data Security
          </Heading>
          <Text fontSize="lg">
            We take data security seriously and implement appropriate measures
            to protect your personal information. However, please note that no
            method of transmission over the internet is 100% secure.
          </Text>

          <Heading as="h2" size="xl">
            Your Rights
          </Heading>
          <Text fontSize="lg">
            You have the right to access, update, or delete your personal
            information. If you have any questions or requests regarding your
            data, please contact us.
          </Text>

          <Heading as="h2" size="xl">
            Contact Us
          </Heading>
          <Text fontSize="lg">
            If you have any questions about this privacy policy or our privacy
            practices, please feel free to contact us through our contact page.
          </Text>
        </VStack>
      </Box>
    </>
  );
};

export default PrivacyPage;
