import React, { useState } from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  Flex,
  Progress,
  Radio,
  RadioGroup,
  Stack,
  useColorModeValue,
  useToast,
  VStack,
  HStack,
  Select,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Link,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import SEO from "../components/SEO";

ChartJS.register(ArcElement, Tooltip, Legend);

const categories = [
  "Purpose and Ambition",
  "Emotional Intelligence",
  "Integrity and Honesty",
  "Resilience",
  "Confidence without Arrogance",
  "Leadership",
  "Self-Awareness",
  "Health and Self-Care",
  "Effective Communication",
  "Consistency",
  "Respect for Others",
  "Humility",
];

const questions = [
  // Purpose and Ambition
  {
    id: "q1",
    question:
      "I have clear goals for my future and work toward them consistently.",
    category: 0,
  },
  {
    id: "q2",
    question: "I prioritize my time and energy based on my goals.",
    category: 0,
  },
  {
    id: "q3",
    question:
      "I am driven by a sense of purpose in both my personal and professional life.",
    category: 0,
  },

  // Emotional Intelligence
  {
    id: "q4",
    question: "I am aware of my emotions and can manage them effectively.",
    category: 1,
  },
  {
    id: "q5",
    question: "I empathize with others and understand their emotional states.",
    category: 1,
  },
  {
    id: "q6",
    question: "I handle conflicts and disagreements with calmness and respect.",
    category: 1,
  },

  // Integrity and Honesty
  {
    id: "q7",
    question: "I keep my promises and follow through on commitments.",
    category: 2,
  },
  {
    id: "q8",
    question: "I act according to my values, even when it’s difficult.",
    category: 2,
  },
  {
    id: "q9",
    question:
      "I am honest with myself and others, even when the truth is uncomfortable.",
    category: 2,
  },

  // Resilience
  {
    id: "q10",
    question: "I bounce back quickly from setbacks and challenges.",
    category: 3,
  },
  {
    id: "q11",
    question: "I remain optimistic and solution-focused during tough times.",
    category: 3,
  },
  {
    id: "q12",
    question: "I view failures as opportunities for growth and learning.",
    category: 3,
  },

  // Confidence without Arrogance
  {
    id: "q13",
    question:
      "I believe in my abilities without needing validation from others.",
    category: 4,
  },
  {
    id: "q14",
    question:
      "I approach challenges with confidence but remain open to feedback.",
    category: 4,
  },
  {
    id: "q15",
    question: "I do not feel the need to prove myself by putting others down.",
    category: 4,
  },

  // Leadership
  {
    id: "q16",
    question: "I inspire others through my actions and words.",
    category: 5,
  },
  {
    id: "q17",
    question:
      "I take responsibility for leading and supporting those around me.",
    category: 5,
  },
  {
    id: "q18",
    question: "I encourage collaboration and value different perspectives.",
    category: 5,
  },

  // Self-Awareness
  {
    id: "q19",
    question: "I regularly reflect on my actions and their impact.",
    category: 6,
  },
  {
    id: "q20",
    question: "I recognize my strengths and areas where I need to improve.",
    category: 6,
  },
  {
    id: "q21",
    question: "I seek feedback to better understand myself and grow.",
    category: 6,
  },

  // Health and Self-Care
  {
    id: "q22",
    question:
      "I maintain a healthy diet, exercise regularly, and get enough rest.",
    category: 7,
  },
  {
    id: "q23",
    question: "I manage stress effectively and prioritize mental health.",
    category: 7,
  },
  {
    id: "q24",
    question: "I invest time in hobbies and activities that enrich my life.",
    category: 7,
  },

  // Effective Communication
  {
    id: "q25",
    question:
      "I communicate my thoughts clearly and listen actively to others.",
    category: 8,
  },
  {
    id: "q26",
    question:
      "I adapt my communication style depending on the situation and the audience.",
    category: 8,
  },
  {
    id: "q27",
    question: "I address misunderstandings directly and respectfully.",
    category: 8,
  },

  // Consistency
  {
    id: "q28",
    question: "I am reliable and people can count on me to follow through.",
    category: 9,
  },
  {
    id: "q29",
    question:
      "I am consistent in my actions and decisions, regardless of circumstances.",
    category: 9,
  },
  {
    id: "q30",
    question: "I build trust by being dependable and keeping my word.",
    category: 9,
  },

  // Respect for Others
  {
    id: "q31",
    question:
      "I treat everyone with kindness and fairness, regardless of their background.",
    category: 10,
  },
  {
    id: "q32",
    question: "I am respectful even when I disagree with someone.",
    category: 10,
  },
  {
    id: "q33",
    question: "I value and appreciate the contributions of others.",
    category: 10,
  },

  // Humility
  {
    id: "q34",
    question: "I acknowledge when I am wrong and learn from my mistakes.",
    category: 11,
  },
  {
    id: "q35",
    question:
      "I seek opportunities for growth, knowing there is always room to improve.",
    category: 11,
  },
  {
    id: "q36",
    question: "I celebrate the successes of others without feeling threatened.",
    category: 11,
  },
];

const QuizProgress = ({ currentCategory, totalCategories }) => {
  const progressPercentage = (currentCategory / totalCategories) * 100;

  return (
    <Box mb={8}>
      {/* <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Text fontSize="lg" fontWeight="bold">
          Progress: {currentCategory}/{totalCategories}
        </Text>
        <Text fontSize="lg" fontWeight="bold">
          {Math.round(progressPercentage)}%
        </Text>
      </Flex> */}
      <Progress
        value={progressPercentage}
        size="lg"
        borderRadius="md"
        hasStripe
        isAnimated
        colorScheme="teal"
        bgGradient="linear(to-r, teal.500, teal.300)"
      />
    </Box>
  );
};

const generateResults = (totalScore) => {
  if (totalScore >= 180) {
    return {
      message:
        "You are well on your way to embodying the traits of a high-value man.",
    };
  } else if (totalScore >= 144) {
    return {
      message:
        "You have many of the qualities of a high-value man but may need to focus on certain areas.",
    };
  } else if (totalScore >= 108) {
    return {
      message:
        "You possess some high-value traits but should prioritize further self-development.",
    };
  } else {
    return {
      message:
        "There are significant areas for growth, but with dedication, you can improve and become a high-value man.",
    };
  }
};

const Quiz = ({
  questions,
  answers,
  setAnswers,
  categories,
  onComplete,
  scores,
  setScores,
}) => {
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);

  const currentCategoryQuestions = questions.filter(
    (q) => q.category === currentCategoryIndex
  );

  const handleSelectChange = (questionId, value) => {
    setAnswers({
      ...answers,
      [questionId]: Number(value),
    });
  };

  const handleNextCategory = () => {
    const updatedScores = [...scores];
    currentCategoryQuestions.forEach((q) => {
      updatedScores[q.category] += answers[q.id] || 0;
    });

    setScores(updatedScores);

    if (currentCategoryIndex < categories.length - 1) {
      setCurrentCategoryIndex(currentCategoryIndex + 1);
    } else {
      onComplete(updatedScores);
    }
  };

  const handlePrevCategory = () => {
    if (currentCategoryIndex > 0) {
      setCurrentCategoryIndex((prevStep) => prevStep - 1);
    }
  };

  const progressValue = (currentCategoryIndex / categories.length) * 100;

  const bgColor = useColorModeValue("gray.100", "gray.700");
  const textColor = useColorModeValue("gray.800", "gray.200");

  return (
    <VStack spacing={6} align="stretch" bg={bgColor} p={8} borderRadius="md">
      <Box mb={4}>
        <Heading as="h2" size="lg" mb={2} color={textColor}>
          {categories[currentCategoryIndex]}
        </Heading>
        <Flex justify="space-between" align="center" mb={2}>
          <Text fontSize="sm" color={textColor}>
            Category {currentCategoryIndex} of {categories.length}
          </Text>
          <Text fontSize="sm" color={textColor}>
            {Math.floor(progressValue)}% Complete
          </Text>
        </Flex>
        <QuizProgress
          currentCategory={currentCategoryIndex}
          totalCategories={categories.length}
        />
      </Box>

      <VStack spacing={4} align="stretch">
        {currentCategoryQuestions.map((question) => (
          <Box key={question.id} mb={4}>
            <Text mb={2} color={textColor}>
              {question.question}
            </Text>
            <Select
              placeholder="Select a score"
              value={answers[question.id] || "0"}
              onChange={(e) => handleSelectChange(question.id, e.target.value)}
            >
              <option value="1">1 - Strongly Disagree</option>
              <option value="2">2 - Disagree</option>
              <option value="3">3 - Neutral</option>
              <option value="4">4 - Agree</option>
              <option value="5">5 - Strongly Agree</option>
            </Select>
          </Box>
        ))}
      </VStack>

      <Flex justify="space-between" mt={8}>
        <Button
          colorScheme="gray"
          onClick={handlePrevCategory}
          isDisabled={currentCategoryIndex === 0}
        >
          Back
        </Button>
        <Button colorScheme="teal" onClick={handleNextCategory}>
          {currentCategoryIndex < categories.length - 1
            ? "Next"
            : "Calculate Score"}
        </Button>
      </Flex>
    </VStack>
  );
};

const CalculatorPage = () => {
  const [answers, setAnswers] = useState({});
  const [scores, setScores] = useState(Array(categories.length).fill(0));
  const [finalScore, setFinalScore] = useState(null);
  const [categoryScore, setCategoryScore] = useState(null);
  const toast = useToast();

  const bg = useColorModeValue("gray.50", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const resultsBgColor = useColorModeValue("gray.100", "gray.700");

  const resetQuiz = () => {
    setAnswers({});
    setFinalScore(null);

    setScores(Array(categories.length).fill(0));
  };

  const handleQuizComplete = (updatedScores) => {
    const totalScore = updatedScores.reduce((acc, curr) => acc + curr, 0);
    const categoryScores = categories.map((category, index) => {
      const numQuestionsInCategory = questions.filter(
        (q) => q.category === index
      ).length;
      return {
        category: category,
        score: updatedScores[index],
        maxScore: numQuestionsInCategory * 5, // Maximum score for this category
      };
    });

    setScores(updatedScores);
    setFinalScore(totalScore);
    setCategoryScore(categoryScores);

    toast({
      title: "Calculation Complete",
      description: "Your High Value Man score has been calculated.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const chartTextColor = useColorModeValue("#000000", "#FFFFFF");

  const pieOptions = {
    plugins: {
      legend: {
        position: "bottom", // or 'top', 'left', 'right',
        labels: {
          color: chartTextColor, // Dynamically set text color based on color mode
        },
      },
      layout: {
        padding: {
          bottom: 30, // Add padding to create space between chart and legend
        },
      },
    },
  };

  const getPieData = (categoryScore) => ({
    labels: categoryScore.map((cs) => cs.category),
    datasets: [
      {
        label: "Category Scores",
        data: categoryScore.map((cs) => cs.score),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],
      },
    ],
  });

  return (
    <>
      <SEO
        title="High Value Man Score Calculator - Assess Your Traits"
        description="Calculate your High Value Man Score based on key traits like leadership, confidence, and emotional intelligence. Discover areas for improvement and start your journey to becoming a High Value Man."
        keywords="High Value Man Score,High Value Man Score Calculator, personal traits assessment, self-improvement, leadership, emotional intelligence"
        url="https://www.highvalueman.life/high-value-man-calculator"
      />
      <Box bg={bg} p={8}>
        <Heading color={textColor} textAlign="center" mb={6}>
          Discover Your High Value Man Score
        </Heading>
        <Text fontSize="lg" color={textColor} textAlign="center" mb={8}>
          Take this quiz to evaluate where you stand as a High Value Man and
          identify areas where you excel and where you can improve. This
          comprehensive assessment covers key qualities like Purpose and
          Ambition, Emotional Intelligence, Integrity, and Leadership. By the
          end of the quiz, you’ll gain valuable insights into your strengths and
          areas that need focus, helping you on your journey to becoming the
          best version of yourself.
        </Text>
        {finalScore === null ? (
          <Quiz
            questions={questions}
            categories={categories}
            onComplete={handleQuizComplete}
            scores={scores}
            setScores={setScores}
            answers={answers}
            setAnswers={setAnswers}
          />
        ) : (
          <Box mt={8} bg={resultsBgColor}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              direction={{ md: "row", base: "column" }}
              rowGap={4}
              p="8"
            >
              <Heading as="h3" size="md" textAlign="center">
                Your High Value Man Score Summary
              </Heading>

              <Button colorScheme="teal" onClick={resetQuiz}>
                Take Quiz Again
              </Button>
            </Flex>

            <Box textAlign="center">
              <Alert
                status={
                  finalScore >= 180
                    ? "success"
                    : finalScore >= 144
                      ? "info"
                      : "warning"
                }
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                p={4}
              >
                <AlertIcon boxSize="40px" mr={0} />
                <AlertTitle mt={4} mb={1} fontSize="lg">
                  Your High Value Man Score: {finalScore}
                </AlertTitle>
                <AlertDescription maxWidth="sm">
                  {generateResults(finalScore).message}
                </AlertDescription>
              </Alert>
            </Box>

            <Flex
              direction={{ base: "column", lg: "row" }}
              justify="center"
              gap={{ base: 4, lg: 8 }}
              alignItems={{ base: "center", lg: "flex-start" }}
            >
              {/* Pie Chart Section */}
              {finalScore > 0 && (
                <Box mt={8} maxW={"100%"} p={4} textAlign={"center"}>
                  <Heading as="h3" size="md" mb={4}>
                    Score Distribution by Category
                  </Heading>

                  <Box width={"400px"} maxW={"100%"}>
                    <Pie
                      data={getPieData(categoryScore)}
                      options={pieOptions}
                    />
                  </Box>
                </Box>
              )}

              {/* improvements area */}
              <Box mt={8} p={4}>
                <Heading as="h3" size="md" mb={4}>
                  Your Results by Category
                </Heading>

                {/* Needs Improvement Section */}
                <Box>
                  <Heading as="h4" size="sm" mb={2} color="red.500">
                    Needs Improvement
                  </Heading>
                  <VStack align="start" spacing={2}>
                    {categoryScore
                      .filter((cs) => cs.score / cs.maxScore < 0.5)
                      .map((cs) => (
                        <Text key={cs.category} color="red.500">
                          {cs.category}: {cs.score} / {cs.maxScore} (Needs
                          Improvement)
                        </Text>
                      ))}
                  </VStack>
                </Box>

                {/* Doing Good Section */}
                <Box mt={6}>
                  <Heading as="h4" size="sm" mb={2} color="orange.500">
                    Doing Good
                  </Heading>
                  <VStack align="start" spacing={2}>
                    {categoryScore
                      .filter(
                        (cs) =>
                          cs.score / cs.maxScore >= 0.5 &&
                          cs.score / cs.maxScore < 0.75
                      )
                      .map((cs) => (
                        <Text key={cs.category} color="orange.500">
                          {cs.category}: {cs.score} / {cs.maxScore} (Doing Good)
                        </Text>
                      ))}
                  </VStack>
                </Box>

                {/* Excellent Section */}
                <Box mt={6}>
                  <Heading as="h4" size="sm" mb={2} color="green.500">
                    Excellent
                  </Heading>
                  <VStack align="start" spacing={2}>
                    {categoryScore
                      .filter((cs) => cs.score / cs.maxScore >= 0.75)
                      .map((cs) => (
                        <Text key={cs.category} color="green.500">
                          {cs.category}: {cs.score} / {cs.maxScore} (Excellent)
                        </Text>
                      ))}
                  </VStack>
                </Box>
              </Box>
            </Flex>
          </Box>
        )}

        {/* Adding the blog link here */}
        <Box mt={8} textAlign="center" p={4} fontSize={"medium"}>
          <Text mb={2} fontSize={"medium"}>
            Want to understand more about how the High Value Man Score is
            calculated?
          </Text>
          <Link
            as={RouterLink}
            to="/blogs/high-value-man-score"
            color="teal.500"
          >
            Read our in-depth blog post on High Value Man Score Calculation
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default CalculatorPage;
