import React from "react";
import {
  Box,
  Heading,
  Text,
  Image,
  Button,
  useColorModeValue,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { blogImageMap } from "../constants/blogConfig";

const BlogCard = ({ blog }) => {
  const cardBg = useColorModeValue("white", "gray.700");
  const cardTextColor = useColorModeValue("gray.800", "gray.100");
  const buttonColorScheme = useColorModeValue("teal", "cyan");

  return (
    <Box
      bg={cardBg}
      shadow="md"
      borderRadius="md"
      p={4}
      _hover={{ shadow: "lg", transform: "scale(1.03)" }}
      transition="transform 0.2s ease"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%" // Ensures all cards are the same height
    >
      <ChakraLink
        as={RouterLink}
        to={`/blogs/${blog.id}`}
        _hover={{ textDecoration: "none" }} // Remove underline on hover
        height="100%"
        display="flex"
        flexDirection="column"
      >
        <Image
          src={blogImageMap[blog.id]}
          alt={blog.title}
          borderRadius="md"
          mb={4}
          objectFit="cover"
          maxHeight="200px"
          w="100%"
        />
        <Box flex="1" mb={4}>
          <Heading as="h3" size="md" mb={2} color={cardTextColor}>
            {blog.title}
          </Heading>
          <Text color={cardTextColor} noOfLines={3} my={4} fontWeight="normal">
            {blog.content && blog.content.replace(/<\/?[^>]+(>|$)/g, "")}
            {/* Strips HTML tags for preview */}
          </Text>
        </Box>
        <Button colorScheme={buttonColorScheme} size="sm" mt="auto">
          Read More
        </Button>
      </ChakraLink>
    </Box>
  );
};

export default BlogCard;
