// theme.js
import { extendTheme } from "@chakra-ui/react";

const colors = {
  primary: {
    50: "#ffe5e5",
    100: "#fcb8b8",
    200: "#f28a8a",
    300: "#e95b5b",
    400: "#df2c2c",
    500: "#c61212",
    600: "#9b0e0e",
    700: "#700a0a",
    800: "#450606",
    900: "#1e0202",
  },
  secondary: {
    50: "#e5f6ff",
    100: "#b8e4ff",
    200: "#8ad2ff",
    300: "#5cc0ff",
    400: "#2eaefe",
    500: "#1595e5",
    600: "#0e74b3",
    700: "#085281",
    800: "#00304f",
    900: "#001020",
  },
  accent: {
    50: "#e6fff1",
    100: "#b9ffd9",
    200: "#8cffc2",
    300: "#5fffab",
    400: "#32ff94",
    500: "#19e67b",
    600: "#13b35f",
    700: "#0d8044",
    800: "#074c28",
    900: "#02190d",
  },
};

const config = {
  initialColorMode: "dark",
  useSystemColorMode: true,
};

const theme = extendTheme({
  colors,
  config,
  fonts: {
    heading: "'Open Sans', sans-serif",
    body: "'Raleway', sans-serif",
  },
  styles: {
    global: (props) => ({
      body: {
        bg: props.colorMode === "dark" ? "gray.900" : "gray.50",
        color: props.colorMode === "dark" ? "gray.50" : "gray.900",
      },
    }),
  },
});

export default theme;
