// AboutPage.js
import React from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Image,
  Button,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import SEO from "../components/SEO";

const AboutPage = () => {
  const bg = useColorModeValue("gray.100", "gray.700");
  const textColor = useColorModeValue("gray.800", "white");
  const missionBg = useColorModeValue("gray.200", "gray.600");

  return (
    <>
      <SEO
        title="About Us - High Value Man"
        description="Learn about High Value Man and our mission to help men achieve their full potential. Discover the key traits that define a High Value Man and how you can embody them."
        keywords="About High Value Man, High Value Man, our mission, personal development, leadership, emotional intelligence"
        url="https://www.highvalueman.life/about"
      />
      <Box bg={bg} color={textColor} py={10}>
        {/* Hero Section */}
        <VStack spacing={8} textAlign="center" p={8}>
          <Heading as="h1" size="2xl">
            About Us
          </Heading>
          <Text fontSize="lg" maxW="800px">
            Welcome to High Value Man - your guide to becoming the best version
            of yourself. We are dedicated to helping men discover and cultivate
            the qualities that define true value and success.
          </Text>
        </VStack>

        {/* Mission Section */}
        <Box bg={missionBg} p={8} mt={10} borderRadius="md">
          <Heading as="h2" size="xl" mb={4} textAlign="center">
            Our Mission
          </Heading>
          <Text fontSize="lg" textAlign="center" maxW="800px" mx="auto">
            At High Value Man, our mission is to empower men to achieve their
            full potential by embracing integrity, confidence, leadership, and
            self-awareness. We believe that by fostering these qualities, every
            man can lead a fulfilling and impactful life.
          </Text>
        </Box>

        {/* Key Features Section */}
        <VStack spacing={8} mt={10} align="center" p={8}>
          <Heading as="h2" size="xl">
            What We Offer
          </Heading>
          <Text fontSize="lg" maxW="800px" textAlign="center">
            From insightful articles and tools to comprehensive guides, we
            provide everything you need to grow as a High Value Man. Join our
            community and start your journey today.
          </Text>
          <Button colorScheme="teal" size="lg" as={Link} to="/">
            Explore More
          </Button>
        </VStack>
      </Box>
    </>
  );
};

export default AboutPage;
