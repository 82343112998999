import {
  Box,
  Flex,
  Heading,
  Link as ChakraLink,
  Button,
  useColorMode,
  useColorModeValue,
  IconButton,
  Image,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { SunIcon, MoonIcon, HamburgerIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import LogoImg from "../images/logo.png";

const Header = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const bg = useColorModeValue("primary.500", "primary.800");
  const color = useColorModeValue("white", "gray.200");

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box as="header" bg={bg} color={color} py={4}>
      <Flex
        mx="auto"
        align="center"
        justify="space-between"
        px={8}
        direction="row"
      >
        <ChakraLink as={RouterLink} to="/" _hover={{ textDecoration: "none" }}>
          <Flex align="center">
            <Image alt="High Value Man" boxSize="40px" src={LogoImg} />
            <Heading as="h1" size="lg" ml={4}>
              High Value Man
            </Heading>
          </Flex>
        </ChakraLink>

        <Flex align="center">
          {/* Desktop Menu */}
          <Flex display={{ base: "none", md: "flex" }} align="center">
            <Button
              colorScheme="teal"
              ml={{ base: 0, md: 5 }}
              mb={{ base: 4, md: 0 }}
              mr={6}
              size={{ base: "sm", md: "md" }}
              as={RouterLink}
              to="/high-value-man-calculator"
            >
              Calculate Your Score
            </Button>

            <ChakraLink as={RouterLink} to="/blogs" mx={3}>
              Blogs
            </ChakraLink>
            <ChakraLink as={RouterLink} to="/about" mx={3}>
              About
            </ChakraLink>
            <ChakraLink as={RouterLink} to="/contact" mx={3}>
              Contact
            </ChakraLink>
          </Flex>

          {/* Mobile Menu Icon */}
          <IconButton
            aria-label="Open menu"
            icon={<HamburgerIcon />}
            onClick={onOpen}
            display={{ base: "flex", md: "none" }}
            ml={4}
          />

          <IconButton
            aria-label="Toggle dark mode"
            icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            onClick={toggleColorMode}
            ml={4}
          />
        </Flex>
      </Flex>

      {/* Mobile Drawer */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Menu</DrawerHeader>
            <DrawerBody>
              <VStack spacing={8} align="start">
                <Button
                  colorScheme="teal"
                  size="md"
                  as={RouterLink}
                  to="/high-value-man-calculator"
                  onClick={onClose}
                >
                  Calculate Your Score
                </Button>

                <ChakraLink as={RouterLink} to="/blogs" onClick={onClose}>
                  Blogs
                </ChakraLink>
                <ChakraLink as={RouterLink} to="/about" onClick={onClose}>
                  About
                </ChakraLink>
                <ChakraLink as={RouterLink} to="/contact" onClick={onClose}>
                  Contact
                </ChakraLink>
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Box>
  );
};

export default Header;
