import React from "react";
import { Helmet } from "react-helmet-async";

const SEO = ({
  title = "High Value Man - Discover the Traits and Journey",
  description = "Explore the key traits of a High Value Man, including leadership, confidence, and emotional intelligence. Calculate your High Value Man Score and embark on your personal development journey.",
  keywords = "High Value Man, High Value Man Calculator, High Value Man Score, personal development, leadership, confidence, emotional intelligence, self-improvement",
  url = "https://www.highvalueman.life",
  image = "ttps://www.highvalueman.life/hero-image.jpg",
  author = "High Value Man Team",
  type = "website",
  locale = "en_US",
}) => {
  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={author} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {/* Open Graph / Facebook */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:locale" content={locale} />
      <meta property="og:site_name" content="High Value Man" />
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      {/* Additional Meta Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="theme-color" content="#2b6cb0" />

      {/* Canonical Link */}
      <link rel="canonical" href={url} />

      {/* Favicon */}
      <link rel="icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
    </Helmet>
  );
};

export default SEO;
