import {
  Box,
  Heading,
  SimpleGrid,
  VStack,
  Link as ChakraLink,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { blogConfig } from "../constants/blogConfig";
import { Link as RouterLink } from "react-router-dom";

// Footer Component
export default function Footer() {
  const bg = useColorModeValue("gray.400", "gray.900");
  const textColor = useColorModeValue("gray.800", "white");

  return (
    <Box as="footer" bgColor={bg} color={textColor} py={8} px={8}>
      <SimpleGrid columns={[1, 2, 3]} spacing={[8, 24]}>
        <VStack align="flex-start">
          <ChakraLink as={Link} to="/high-value-man-calculator">
            <Heading size="md" mb={4}>
              Calculate Your Score
            </Heading>
          </ChakraLink>

          {blogConfig.slice(0, 6).map((blog) => (
            <ChakraLink as={Link} to={`/blogs/${blog.id}`} key={blog.id}>
              {blog.miniTitle}
            </ChakraLink>
          ))}
        </VStack>

        <VStack align="flex-start">
          <ChakraLink as={Link} to="/blogs">
            <Heading size="md" mb={4}>
              Blogs
            </Heading>
          </ChakraLink>

          {blogConfig.slice(6).map((blog) => (
            <ChakraLink as={Link} to={`/blogs/${blog.id}`} key={blog.id}>
              {blog.miniTitle}
            </ChakraLink>
          ))}
        </VStack>

        <VStack align="flex-start">
          <ChakraLink as={RouterLink} to="/about">
            About
          </ChakraLink>
          <ChakraLink as={RouterLink} to="/contact">
            Contact
          </ChakraLink>
          <ChakraLink as={RouterLink} to="/privacy-policy">
            Privacy Policy
          </ChakraLink>
        </VStack>
      </SimpleGrid>
    </Box>
  );
}
