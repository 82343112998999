import React from "react";
import {
  Box,
  Heading,
  SimpleGrid,
  Button,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { blogConfig } from "../constants/blogConfig";
import BlogCard from "../components/BlogCard";
import SEO from "../components/SEO";

const BlogList = () => {
  const bg = useColorModeValue("gray.200", "gray.600");
  const textColor = useColorModeValue("gray.800", "white");

  return (
    <>
      <SEO
        title="High Value Man Blog - Insights on Traits, Leadership, and Personal Growth"
        description="Dive into articles that explore the key traits of a High Value Man. From leadership to emotional intelligence, find valuable insights to guide your self-improvement journey."
        keywords="High Value Man blog, personal growth, leadership tips, personal development, leadership insights,confidence, emotional intelligence, self-improvement"
        url="https://www.highvalueman.life/blogs"
      />
      <VStack align="start" spacing={8} bg={bg} p={8} borderRadius="md">
        <Heading as="h2" size="xl" color={textColor}>
          Latest Blogs
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
          {blogConfig.map((blog) => (
            <BlogCard blog={blog} />
          ))}
        </SimpleGrid>
      </VStack>
    </>
  );
};

export default BlogList;
