// pages/HomePage.js
import React from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  useColorModeValue,
  VStack,
  HStack,
  SimpleGrid,
  Grid,
  GridItem,
  Image,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import HeroImage from "../images/hero-image.jpg";
import HighValueDefinition from "../images/high-value-definiton.jpg";
import { blogConfig } from "../constants/blogConfig";
import BlogCard from "../components/BlogCard";
import SEO from "../components/SEO";

const HomePage = () => {
  return (
    <>
      <SEO
        title="High Value Man - Discover the Traits and Calculate Your Score"
        description="Learn what it means to be a High Value Man. Explore the traits, take the High Value Man Score calculator, and start your journey to self-improvement and success."
        keywords="High Value Man, High Value Man Calculator, High Value Man Score, personal development, leadership, confidence, emotional intelligence, self-improvement, self-improvement"
      />
      <Box>
        <HeroSection />
        <DefinitionSection />
        <QualitiesSection />
        <BlogsSection />
      </Box>
    </>
  );
};

const HeroSection = () => {
  const textColor = useColorModeValue("gray.900", "whiteAlpha.900");
  const bg = useColorModeValue("gray.100", "gray.700");

  return (
    <Box bg={bg} py={{ base: 12, md: 16, lg: 20 }} px={{ base: 4, md: 12 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems="center"
        justifyContent="space-between"
        gap={8}
      >
        {/* Content Section */}
        <Box
          flex="1"
          textAlign={{ base: "center", md: "left" }}
          mb={{ base: 8, md: 0 }}
        >
          <Heading as="h2" size="xl" color={textColor}>
            Discover the High Value Man in You
          </Heading>
          <Text mt={4} fontSize={{ base: "md", md: "lg" }} color={textColor}>
            Understand what it means to be a High Value Man and start your
            journey to becoming the best version of yourself.
          </Text>
          <Button
            as={RouterLink}
            to="/high-value-man-calculator"
            mt={6}
            size="lg"
            colorScheme="teal"
          >
            Calculate Your Score Now
          </Button>
        </Box>

        {/* Image Section */}
        <Flex flex="1" justifyContent="center">
          <img
            src={HeroImage}
            alt="High Value Man"
            style={{
              width: "90%",
              maxWidth: "600px",
              height: "auto",
              borderRadius: "8px",
            }}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

const DefinitionSection = () => {
  const textColor = useColorModeValue("gray.900", "whiteAlpha.900");
  const bg = useColorModeValue("gray.200", "gray.600");

  return (
    <Flex
      bg={bg}
      py={{ base: 12, md: 16, lg: 20 }}
      px={{ base: 4, md: 12 }}
      align="center"
      justify="space-between"
      direction={{ base: "column", md: "row" }}
      gap={8}
    >
      {/* Image on the left */}
      <Flex
        flex="1"
        order={{ base: 2, md: 1 }}
        mt={{ base: 8, md: 0 }}
        justifyContent="center"
      >
        <img
          src={HighValueDefinition}
          alt="High Value Man Definitoon"
          style={{
            borderRadius: "8px",
            width: "90%",
            maxWidth: "600px",
            height: "auto",
          }}
        />
      </Flex>

      {/* Content on the right */}
      <Box
        flex="1"
        order={{ base: 1, md: 2 }}
        px={{ base: "5%", md: 0 }}
        ml={{ md: 8 }}
      >
        <Heading as="h3" size="xl" color={textColor}>
          What Does High Value Man Mean?
        </Heading>
        <Text mt={4} fontSize="md" color={textColor}>
          A High Value Man is someone who consistently strives to improve
          himself, maintains strong personal values, and has a positive impact
          on those around him. He is confident, resilient, and knows his worth,
          without needing to prove it to others.
        </Text>
      </Box>
    </Flex>
  );
};

const QualitiesSection = () => {
  const bg = useColorModeValue("gray.100", "gray.700");
  const textColor = useColorModeValue("gray.800", "white");
  const qualityBg1 = useColorModeValue("blue.50", "blue.900");
  const qualityBg2 = useColorModeValue("green.50", "green.900");
  const qualityBg3 = useColorModeValue("orange.50", "orange.900");
  const qualityBg4 = useColorModeValue("purple.50", "purple.900");
  const qualityBg5 = useColorModeValue("teal.50", "teal.900");

  const backgrounds = [
    qualityBg1,
    qualityBg2,
    qualityBg3,
    qualityBg4,
    qualityBg5,
  ];

  const qualities = [
    {
      title: "Self-Awareness",
      description:
        "A High Value Man knows his strengths and weaknesses and continuously seeks to improve. He understands his emotions and actions, ensuring they align with his values and goals.",
    },
    {
      title: "Purpose-Driven",
      description:
        "He has clear goals and a strong sense of purpose. Every action he takes is aligned with his long-term objectives, whether in career, relationships, or personal growth.",
    },
    {
      title: "Resilience",
      description:
        "He faces challenges head-on and doesn’t back down when times get tough. His ability to stay strong and adapt during adversity is a defining trait.",
    },
    {
      title: "Confidence",
      description:
        "True confidence comes from within. A High Value Man believes in himself and his abilities without being arrogant. He is secure in who he is and doesn’t seek validation from others.",
    },
    {
      title: "Integrity",
      description:
        "He stands by his word and acts with honesty. His actions are consistent with his values, even when no one is watching.",
    },
    {
      title: "Emotional Intelligence",
      description:
        "Understanding and managing emotions, both his own and others', is key. He knows how to handle relationships with empathy and respect.",
    },
    {
      title: "Consistency",
      description:
        "He is reliable and consistent in his actions, whether in his personal or professional life. People know they can count on him.",
    },
    {
      title: "Healthy Relationships",
      description:
        "He fosters positive and supportive relationships. He knows the importance of surrounding himself with people who uplift and motivate him.",
    },
    {
      title: "Continuous Improvement",
      description:
        "He is committed to lifelong learning and self-development. He never settles and always looks for ways to grow.",
    },
    {
      title: "Self-Care",
      description:
        "He takes care of his physical, mental, and emotional well-being. This includes regular exercise, healthy eating, and mindfulness practices.",
    },
  ];

  return (
    <Box bg={bg} py={16} px={8}>
      <Heading as="h3" size="xl" textAlign="center" color={textColor}>
        Qualities of a High Value Man
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} mt={8}>
        {qualities.map((quality, index) => (
          <Box
            key={index}
            p={8}
            borderWidth="1px"
            borderRadius="lg"
            shadow="md"
            bg={backgrounds[index % backgrounds.length]}
          >
            <Heading fontSize="xl" color={textColor}>
              {quality.title}
            </Heading>
            <Text mt={4} color={textColor}>
              {quality.description}
            </Text>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

const BlogsSection = () => {
  const bg = useColorModeValue("gray.200", "gray.600");

  return (
    <Box bgColor={bg} py={16} px={8}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={6}
      >
        <Heading as="h2" size="xl">
          Latest Blogs
        </Heading>
        <Button
          as={RouterLink}
          to="/blogs"
          colorScheme="teal"
          _hover={{ textDecoration: "none" }}
        >
          View More
        </Button>
      </Box>

      <Grid
        templateColumns={{
          base: "1fr",
          md: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)",
        }}
        gap={6}
      >
        {blogConfig.slice(0, 6).map((blog) => (
          <GridItem key={blog.id}>
            <BlogCard blog={blog} /> {/* Reusing the BlogCard component */}
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};

export default HomePage;
